export const works = [
  {
    title: "Minuza App",
    description:
      "BRD in-house app for disbursing & managing students' loan (Government sponsored students).",
    link: "https://minuza.brd.rw",
  },
  {
    title: "Iwanjye App",
    description:
      "A digital platform that will link applicants, financial institutions, and affordable housing developers.",
    link: "https://iwanjye.brd.rw",
  },
  {
    title: "Color Palette and Gradients",
    description: "Curated list of colour Palettes and Gradients",
    stacks: ["ReactJS", "Sass"],
    link: "https://coloursheet.com",
  },
  {
    title: "Fleetcon App",
    description:
      "Technology platform that enables end-to-end management of fleet and business operations for logistics companies",
    stacks: ["ReactJs", "NodeJs/ExpressJs", "Postgres/sequelize"],
    link: "https://app.fleetcon.com",
  },
  {
    title: "Shecancode LMS",
    description: "TLearning Management System for girls learning to code",
    stacks: ["ReactJs", "Java(Spring-boot)", "MongoDB"],
    link: "https://shecancode-materials.netlify.app"
  },
  {
    title: "Sisonke",
    description: "Marketplace that enables small businesses to source products at lower prices and access other needed services and offerings",
    stacks: ["ReactJs", "NodeJs", "Postgres/Sequelize"],
    link: "https://app.sisonke.africa"
  },
  {
    title: "Isuzume",
    description: "Web-based platform for provisional driving test preparation",
    stacks: ["ReactJs", "NodeJs/Express", "MongoDB"],
    link: "https://isuzume.rw",
  },
  {
    title: "Free Rwanda administrative structure API",
    description:
      "API that provides administrative structure of Rwanda like provinces, districts, sectors, cells and villages",
    stacks: ["Nodejs", "Heroku"],
    link: "https://rapidapi.com/victorkarangwa4/api/rwanda",
    redirect: "RAPID API link"
  },
  {
    title: "Mobile HEalth Clinic - EHR",
    description: "Mobile Health Clinic Foundation that uses Electronic HEalth Records",
    stacks: ["ReactJs/NextJs", "NestJs", "Postgres/Type ORM"],
    link: "https://www.mhcfehr.co.za",
  },
  {
    title: "Custom React Pages",
    description:
      "React pagination package. Found on NPM(Node Package Manager) ",
    stacks: ["ReactJs"],
    link: "https://www.npmjs.com/package/custom_react_pages",
    redirect: "NPM link"
  },
];
