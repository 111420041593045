import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "assets/styles/globalStyles.scss";
import { Provider } from "react-redux";
import dotenv from "dotenv";
import store from "redux/store";

dotenv.config();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById("root")
);
