export const skills = [
  "ReactJs",
  "NodeJs",
  "Java(Spring Boot)",
  "NestJs",
  "NextJs",
  "Flutter",
  "Typescript",
  "React Native",
  "UI/UX Design",
  "Firebase",
  "REST",
  "WordPress",
  "RabbitMQ",
  "GraphicQL",
  "Git & GitHub",
  "CSS",
  "PostgreSQL",
  "CI/CD",
  "ExpressJS",
  "AWS",
  "Heroku",
  "Docker",
];
