import ContactPage from "pages/Contact";
import LandingPage from "pages/LandingPage";
import SkillsPage from "pages/Skills";
import WorksPage from "pages/Works";
import React from "react";
import { Route, Switch } from "react-router-dom";

const routes = () => {
  return (
    <Switch>
      <Route exact path={["/", "/home", "/about"]} component={LandingPage} />
      <Route exact path={"/works"} component={WorksPage} />
      <Route exact path={"/skills"} component={SkillsPage} />
      <Route exact path={"/contact"} component={ContactPage} />
    </Switch>
  );
};

export default routes;
