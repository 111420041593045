import React from "react";
import MainLayout from "layouts/Main";
import Card from "components/Card";
import { works } from "constant/works";

const WorksPage = () => {
  return (
    <MainLayout title="Works">
      <div className="c-body w-100 d-flex flex-row justify-content-around flex-wrap">
        {works.map((data, i) => (
          <Card data={data} />
        ))}
      </div>
    </MainLayout>
  );
};

export default WorksPage;
