import React from "react";
import "./style.scss";

const Card = ({ data }) => {
  const { title, description, redirect, link } = data;
  return (
    <div className="custom-card d-flex flex-column">
      <div className="project-img"></div>
      <div>
        <h3 className="head-txt">{title}</h3>
        <p>{description}</p>
        <p className="d-flex flex-row">
          <label>Link: </label>&nbsp;
          <span>
            <a href={link} target="_blank">
              {redirect || link}
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Card;
