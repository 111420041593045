import React from "react";
import profileImg from "assets/images/victor_karangwa.jpeg";
import {
  GithubOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import MainLayout from "layouts/Main";

const LandingPage = () => {
  return (
    <MainLayout>
      <div className="bg-body"></div>
      <div className="c-body w-100 d-flex flex-sm-row flex-column justify-content-center">
        <div className="img-container">
          <img alt="victor_profile" src={profileImg} />
        </div>
        <div className="landing w-50 px-5 d-flex flex-row">
          <div className="v-line"></div>
          <div className="detail mx-4 pt-4">
            <span>
              <p>
                Experienced{" "}
                <label className="i-text">Full-stack Developer</label> &{" "}
                <label className="i-text">UI/UX Designer</label> based in Kigali
              </p>
              <p>I design and code awesome stuff. </p>
              <p>I love solving problems with clean and scalable solutions</p>
            </span>
            <div className="icon-container w-50 d-flex flex-row justify-content-around">
              <a href="https://twitter.com/victkarangwa">
                <TwitterOutlined />
              </a>
              <a href="https://github.com/victkarangwa">
                <GithubOutlined />
              </a>
              <a href="https://linkedin.com/in/victkarangwa">
                <LinkedinOutlined />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default LandingPage;
