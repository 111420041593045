import React from "react";
import Header from "components/Header/Header";
import "./style.scss";

const MainLayout = ({ children, title }) => {
  return (
    <div>
      <Header />
      <div className="p-head">
        <label className="page-title">{title}</label>
      </div>

      <div className="main">{children}</div>
    </div>
  );
};

export default MainLayout;
