import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const Header = () => {
  return (
    <div className="main-header">
      <div className="nav-links">
        <ul>
          <li>
            <Link to="/about">About Me</Link>
          </li>
          <li>
            <Link to="/works">Works</Link>
          </li>
          <li>
            <Link to="/skills">Skills</Link>
          </li>
          <li>
            <Link to="/contact">Contact Me</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
