import React from "react";
import MainLayout from "layouts/Main";
import contactImg from "assets/images/contact_img.png";

const ContactPage = () => {
  return (
    <MainLayout title="Contact">
      <div className="c-body px-5 w-100 d-flex flex-row justify-content-center">
        <div className="contact-page w-50">
          <p>
            <label className="i-text">Email address</label>
            <br />{" "}
            <a href="mailto:victkarangwa@gmail.com">victkarangwa@gmail.com</a>
          </p>
          <p>
            <label className="i-text">Phone number</label>
            <br /> <a href="tel:+250789152190">+(250) 789 152 190</a>
          </p>
          <p>
            <label className="i-text">Address</label>
            <br /> Kigali, KG 204 st
          </p>
        </div>
        <div style={{marginTop: "-130px"}} className="w-75 text-center illis">
          <img alt="contact_image" className="w-75" src={contactImg} />
        </div>
      </div>
    </MainLayout>
  );
};

export default ContactPage;
