import React from "react";
import MainLayout from "layouts/Main";
import Skill from "components/Skill";
import { skills } from "constant/skills";

const SkillsPage = () => {
  return (
    <MainLayout title="Skills">
      <div className="c-body skill-set px-sm-5 px-2 w-100 d-flex flex-row justify-content-sm-flex-start justify-content-between flex-wrap">
        {skills.map((name, i) => (
          <Skill name={name} />
        ))}
      </div>
    </MainLayout>
  );
};

export default SkillsPage;
